import {extend, override} from "flarum/extend";
import app from "flarum/app";
import HeaderSecondary from "flarum/components/HeaderSecondary";
import SettingsPage from "flarum/components/SettingsPage";
import LogInModal from "flarum/components/LogInModal";

app.initializers.add('motomoshi-sso', function () {
    override(LogInModal.prototype, 'init', redirectWhenLoginModalIsOpened);

    extend(HeaderSecondary.prototype, 'items', replaceButtons);

    extend(SettingsPage.prototype, 'accountItems', removeProfileActions);
    extend(SettingsPage.prototype, 'settingsItems', checkRemoveAccountSection);

    function redirectWhenLoginModalIsOpened() {
        window.location.href = app.forum.data.attributes['motomoshi-sso.login_url'];
        throw new Error('Stop execution');
    }

    function replaceButtons (items) {
        if (items.has('logIn')) {
            const logInUrl = app.forum.data.attributes['motomoshi-sso.login_url'];
            const logInText = app.translator.trans('core.forum.header.log_in_link');
            replaceButton(items, 'logIn', logInUrl, logInText);
        }

        if (items.has('signUp')) {
            const signUpUrl = app.forum.data.attributes['motomoshi-sso.signup_url'];
            const signUpText = app.translator.trans('core.forum.header.sign_up_link');
            replaceButton(items, 'signUp', signUpUrl, signUpText);
        }
    }

    function replaceButton (items, componentId, buttonHref, buttonText) {
        const referrerUrl = encodeURI(window.location.href);
        const href = `${buttonHref}?referrer=${referrerUrl}`;
        const currentComponent = items.get(componentId);
        items.replace(componentId, <a href={href} className={currentComponent.className}>&nbsp;{buttonText}&nbsp;</a>);
    }

    function removeProfileActions(items) {
        items.remove('changeEmail');
        items.remove('changePassword');
    }

    function checkRemoveAccountSection(items) {
        if (items.has('account') && items.get('account').props.children.length === 0) {
            items.remove('account');
        }
    }
});
